import { delete_cookie } from "sfcookies";
import { jwtDecode } from "jwt-decode";

const getCookie = (name) => { 
  
  const cookieValue = document.cookie.match(
    "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  const cookieData = cookieValue ? cookieValue.pop() : "";
  const data_list = Object.keys(cookieData).length > 0 ? JSON.parse(decodeURIComponent(cookieData)) : {};
  return data_list;
};
 

const isTokenExpired = ()  => {
  const token = getCookie("userDetails");

  if(Object.keys(token).length > 0 ){
    // console.log("here", token)
    const tokenData = jwtDecode(token?.accessToken ? token?.accessToken : "");
    const expirationTimestamp = tokenData.exp;
    const currentTimestamp = Date.now() / 1000; // Convert milliseconds to seconds
    const isExpired =currentTimestamp > expirationTimestamp
    if (isExpired) {
      alert("Token has expired, Please Login again" )
      delete_cookie("auth");
      delete_cookie("token");
      window.location.href= "/"
      document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      return true;
    } else {
      return false;
    } 
  }
  else{
    return true
  }
 
}

export default isTokenExpired;