import { Home, AlertCircle, Bell, Users, Phone, FileText, Activity} from "react-feather";

export const ADMIN = [
  {
    menutitle: "General",
    menucontent: "",
    Items: [
      {
        path: "/admin/devices",
        icon: Home,
        type: "link",
        active: false,
        title: "Devices",
      },
      {
        path: "/admin/emergency",
        icon: AlertCircle,
        type: "sub",
        active: false,
        title: "Emergencies",
        badge: "badge badge-light-danger",
        badgetxt: "",
        children: [
          {
            path: "/admin/active_emergency",
            type: "link",
            active: false,
            title: "Ongoing Emergencies",
            badge: "badge badge-light-danger",
            badgetxt: "2",
          },
          {
            path: "/admin/resolved_emergency",
            type: "link",
            active: false,
            title: "Resolved Emergencies",
          },
        ],
      },
      {
        path: "/admin/recipients",
        icon: Bell,
        type: "link",
        active: false,
        title: "Alert Recipients",
        // badge: "badge badge-light-primary",
        // badgetxt: ""
      },
      {
        path: "/admin/developers_tab",
        icon: Activity,
        type: "link",
        active: false,
        title: "Developers Tab",
      },
      {
        path: "/admin/active_emergency_details",
        title: "",
      },
      {
        path: "/admin/resolved_emergency_details",
        title: "",
      },
    ],
  },
];

export const SUPER_ADMIN = [
  {
    menutitle: "General",
    menucontent: "",
    Items: [
      {
        path: "/super_admin/devices",
        icon: Home,
        type: "link",
        active: false,
        title: "Devices",
      },
      {
        path: "/super_admin/emergency",
        icon: AlertCircle,
        type: "sub",
        active: false,
        title: "Emergencies",
        badge: "badge badge-light-danger",
        badgetxt: "",
        children:[
          {
            path: "/super_admin/active_emergency",
            type: "link",
            active: false,
            title: "Ongoing Emergencies",
            badge: "badge badge-light-danger",
            badgetxt: "",
          },
          {
            path: "/super_admin/resolved_emergency",
            type: "link",
            active: false,
            title: "Resolved Emergencies",
          },

        ]
      },
      {
        path: "/super_admin/recipients",
        icon: Bell,
        type: "link",
        active: false,
        title: "Alert Recipients",
        // badge: "badge badge-light-primary",
        // badgetxt: ""
      },
      {
        path: "/super_admin/contacts",
        icon: Phone,
        type: "link",
        active: false,
        title: "Emergency Contacts",
      },
      {
        path: "/super_admin/threshold",
        icon: FileText,
        type: "link",
        active: false,
        title: "Threshold",
      },
      {
        path: "/super_admin/users",
        icon: Users,
        type: "link",
        active: false,
        title: "Users",
      },
      {
        path: "/super_admin/developers_tab",
        icon: Activity,
        type: "link",
        active: false,
        title: "Developers Tab",
      },
      {
        path: "/super_admin/active_emergency_details",
        title: "",
      },
      {
        path: "/super_admin/resolved_emergency_details",
        title: "",
      },
    ],
  },
];
