import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/final.png";
import { Minimize, LogOut, User } from "react-feather";
import { delete_cookie } from "sfcookies";
import ContextData from "../../hooks/useAuth";
import { getCookie } from "src/services/cookies";
import { useNavigate } from "react-router-dom"; 
 

const Rightbar = (props) => {

  var {auth,setAuth } = ContextData();
  const navigate= useNavigate();

  // auth=getCookie("userDetails")
  const [moonlight, setMoonlight] = useState(false);

  function toCamelCase(inputString) {
    const words = inputString.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    const camelCaseString = words.join(' ');
    return camelCaseString;
  }

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

   const logout = () => {
    delete_cookie("auth");
    delete_cookie("token");
    sessionStorage.removeItem("token");
    document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setAuth({});
    window.location.href= "/"
    // navigate("/", {replace: true})
  };

  const handleChangePassword = () =>{
    const to= auth?.role.toString() === "admin"? "/admin/profile" : "/super_admin/profile"
    navigate(to, {replace: true})

  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          {/* <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li> */}

          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>

          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media d-flex">
              <img src={man} alt="user" />
              <div className="media-body">
                <span> {auth?.username ? auth?.username : ""}</span>
                <p className="mb-0 font-roboto">
                  {auth?.role ? toCamelCase(auth?.role.toString()) : ""} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={logout}>
                <LogOut />
                <span>Logout</span>
              </li>
              <li onClick={handleChangePassword} >
                 <User  />
                 <span>Profile</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;
