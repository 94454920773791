import React, {useEffect } from "react";
import ContextData from "../hooks/useAuth";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import isTokenExpired from "src/services/auth_header";
import { getCookie } from "src/services/cookies";

const RequireAuth = ({ allowedRoles }) => {
  var { auth, setAuth } = ContextData();
  const location = useLocation();

  auth = getCookie("userDetails");
  

  useEffect(() => {
    if (isTokenExpired()) {
      console.log("Token expired")
       setAuth({})
       document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
       sessionStorage.removeItem('token')
    }
  }, []);

  // if user role is found in the allowwed roles list, then user will be allowed to view the screens 
  // else unauthorised screen will be shown.
  // if user is not logged in then login will be displayed.
  return auth?.role?.find((role) => allowedRoles?.includes(role)) !=
    undefined  && auth?.role?.find((role) => allowedRoles?.includes(role))  && !isTokenExpired() ? (
    <Outlet />
  ) : auth?.accessToken && !isTokenExpired() ? (
    <Navigate to={"/unauthorised"} state={{ from: location }} replace />
  ) : (
    <Navigate to={"/"} state={{ from: location }} replace />
  );
};

export default RequireAuth;
