import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import isTokenExpired from "src/services/auth_header";


export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [active_Emergency, setActiveEmergency] = useState("");
  const [notifications, setnotifications] = useState();
  const [interval, setDataInterval] = useState();
  const [emergencyList, setEmergencyList] = useState([]);
  const [devlopersData, setDevelopersData] = useState([]);

  // const API_URL = "http://localhost:5000";
  const API_URL= "https://apis.lifesavr.co.uk";


  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    const cookieData = cookieValue ? cookieValue.pop() : "";
    const data_list =
      Object.keys(cookieData).length > 0
        ? JSON.parse(decodeURIComponent(cookieData))
        : {};
    return data_list;
  };

  const addNormalProperty = (list) => {
    return list.map((item) => ({
      ...item,
      normal_oxygen: item?.oxygen_saturation ? item?.oxygen_saturation >= item?.oxygen_threshold : "",
      normal_respiration: item?.respiration_rate ?  item?.respiration_rate >= item?.respiration_threshold : "",
    }));
  };

  // fetch all active emergency records
  const get_emergencies = (accessToken) => {
    const headers = {
      Authorization: accessToken,
    };
    axios
      .get(API_URL + `/api/emergency/active_emergency_list`, { headers })
      .then((res) => {
        const final_list = addNormalProperty(res.data.output);
        setEmergencyList(final_list);
        setActiveEmergency(final_list.length > 0 ? final_list.length : "");
       
      })
      .catch((err) => {});
  };

  // fetch developers tab data 
  const get_developers_data = (accessToken) => {
    const headers = {
      Authorization: accessToken,
    };
    axios
      .get(API_URL + `/api/emergency/developers_data`, { headers })
      .then((res) => {
        setDevelopersData(res?.data?.output);
      })
      .catch((err) => {});
  };

  // get the data interval specified in the threshold
  const getInterval = (data_list) => {
    if (interval) {
      clearInterval(interval);
      setDataInterval()
    }
    const headers = {
      Authorization: data_list,
    };
    axios
      .get(API_URL + `/api/threshold/get_threshold`, { headers })
      .then((res) => {
        setDataInterval(res?.data?.output);
       
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const data_list = getCookie("userDetails");
    if (data_list) {
      setAuth(data_list);
    }
  }, []);

  useEffect(() => {
    if (auth?.accessToken && !isTokenExpired()) {
      // fetch interval from the server
      getInterval(auth?.accessToken);
    }
  }, [auth]);

  useEffect(() => {
    let interval_1;
    if (
      auth?.accessToken &&
      interval &&
      !isTokenExpired()
    ) {
       // fetch the developers data and active emergency records first time
      get_developers_data(auth?.accessToken);
      get_emergencies(auth?.accessToken);
      
      // fetch the developers data and active emergency records with speicified interval
      interval_1 = setInterval(() => {
        if (
          auth?.accessToken &&
          interval &&
          !isTokenExpired()
        ) {
          get_developers_data(auth?.accessToken);
          get_emergencies(auth?.accessToken);
          
        } else {
          setAuth({});
          clearInterval(interval_1)
        }
      }, interval);
    }
    return () => clearInterval(interval_1);
  }, [auth,interval]);


// set active emergency count to display in the sidebar
  useEffect(()=>{
    const count= emergencyList?.length > 0 ? emergencyList?.length : ""
    setActiveEmergency(count)

  },[emergencyList])

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        API_URL,
        active_Emergency,
        setActiveEmergency,
        notifications,
        setnotifications,
        interval,
        setEmergencyList,
        emergencyList,
        get_emergencies,
        getInterval,
        setDataInterval,
        devlopersData,
        setDevelopersData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
