import React, { Fragment, useEffect } from "react";
import Loader from "../layout/loader";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import { getPermissions, unsubscribeUser } from "../messaging_init_in_sw";
import { getMessaging, onMessage } from "firebase/messaging";
import ContextData from '../hooks/useAuth'
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAoSCCBhtBUmmkvYgNoTG_DYlyAHjLcJiY",
  authDomain: "lifesvr-59908.firebaseapp.com",
  projectId: "lifesvr-59908",
  storageBucket: "lifesvr-59908.appspot.com",
  messagingSenderId: "768125017798",
  appId: "1:768125017798:web:71d8cbd368f89434fa41c7",
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const App = () => {
  
  const {auth, get_emergencies}= ContextData()
  
  useEffect(() => {
    
    // Unsubscribe the user by removing the token from the database
    if ("Notification" in window && "serviceWorker" in navigator) {
      if (Notification.permission == "default") {
        unsubscribeUser();
      }
    }
    // If th user is admin, then get notification permissions
    if(auth?.role?.toString() !== 'super_admin'){
      setTimeout(()=>{
        getPermissions();
      },5000)
    }
   
  // if the user is using the website and recieves a notification, then fetch the emergencies and show the notification
    onMessage(messaging, (payload) => {
      if (payload.data) {
        get_emergencies(auth?.accessToken)      
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(payload.data.title, {
          body:  payload.data.body,
          icon: "/logo192.png",
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: payload.messageId,
        });
        });
        
      }
    });
    
  }, [auth]);

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        {/* Header with user account*/}
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          {/* Sidebar with menu */}
          <Sidebar />
          <div className="page-body">
            {/* All Main Screens */}
            <Outlet />
          </div>
          <Footer />
          {/* <ThemeCustomize/> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default App;
