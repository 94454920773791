import "../src/assets/fonts/Proxima-Font-Family/proximanova-black.otf";
import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthContextProvider from "./contexts/AuthContext";
import RequireAuth from "./components/RequireAuth";
import App from "./components/app";
import "./index.scss";

// pages
const Home = React.lazy(() =>
  import("./components/screens/Devices/devices_tab")
);
const ActiveEmergency = React.lazy(() =>
  import("./components/screens/Emergency/active_emergency_tab")
);

const ResolvedEmergency = React.lazy(() =>
  import("./components/screens/Emergency/resolved_emergency_tab")
);
const ResolvedEmergencyDetails = React.lazy(() =>
  import("./components/screens/Emergency/resolved_emergency_details")
);
const ActiveEmergencyDetails = React.lazy(() =>
  import("./components/screens/Emergency/active_emergency_details")
);
const Users = React.lazy(() =>
  import("./components/screens/Users/all_users")
);
const Threshold = React.lazy(() =>
  import("./components/screens/Threshold/threshold_tab")
);
const EmergencyContacts = React.lazy(() =>
  import("./components/screens/EmergencyContacts/emergency_contacts_tab")
);
const ChangePassword = React.lazy(() =>
  import("./components/screens/Profile/profile_tab")
);
const Notifications = React.lazy(() =>
  import("./components/screens/Notifications/notifications_tab")
);
const DevelopersTab = React.lazy(() =>
  import("./components/screens/RealTimeData/real_time_data")
);
const Logins = React.lazy(() => import("./auth/signin"));
const Error401 = React.lazy(() => import("./pages/errors/error401"));
const Error400 = React.lazy(() => import("./pages/errors/error400"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


const Root = () => {

  return (
    // <React.StrictMode>
    // </React.StrictMode> 
      <BrowserRouter basename="/">
        <AuthContextProvider>
          <Provider store={store}>
            <React.Suspense fallback={loading}>
              <Routes>
              <Route path="/" element={<Logins />}></Route>
                <Route
                  exact
                  path="/unauthorised"
                  element={<Error401 />}
                ></Route>
                <Route exact path="*" element={<Error400 />} />

               <Route element={<App />}>
                  {/* Admin Routes */}
                  <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                  
                    <Route
                      exact
                      path="/admin/devices" 
                      element={<Home />}
                    />
                    <Route
                      exact 
                      path="/admin/active_emergency" 
                      element={<ActiveEmergency />}
                    />
                    <Route
                      exact 
                      path="/admin/resolved_emergency" 
                      element={<ResolvedEmergency />}
                    />
                   <Route
                      exact
                      path="/admin/active_emergency_details/:id" 
                      element={<ActiveEmergencyDetails />}
                    />  
                     <Route
                      exact
                      path="/admin/resolved_emergency_details/:id" 
                      element={<ResolvedEmergencyDetails />}
                    />  
                      <Route
                      exact
                      path="/admin/profile"  
                      element={<ChangePassword />}
                    />  
                      <Route
                      exact 
                      path="/admin/recipients"  
                      element={<Notifications />}
                    />  
                      <Route
                      exact 
                      path="/admin/developers_tab"  
                      element={<DevelopersTab />}
                    />  
                    
                  </Route>     
                               
                  {/* Super Admin Routes */}
                  <Route element={<RequireAuth allowedRoles={["super_admin"]} />}>
                    <Route
                      exact
                      path="/super_admin/devices" 
                      element={<Home />}
                    />
                    <Route
                      exact 
                      path="/super_admin/active_emergency" 
                      element={<ActiveEmergency />}
                    />
                    <Route
                      exact 
                      path="/super_admin/resolved_emergency" 
                      element={<ResolvedEmergency />}
                    />
                   <Route
                      exact
                      path="/super_admin/active_emergency_details/:id" 
                      element={<ActiveEmergencyDetails />}
                    />  
                     <Route
                      exact
                      path="/super_admin/resolved_emergency_details/:id" 
                      element={<ResolvedEmergencyDetails />}
                    />  
                   <Route
                      exact
                      path="/super_admin/users" 
                      element={<Users />}
                    />  
                   <Route
                      exact
                      path="/super_admin/threshold" 
                      element={<Threshold />}
                    />  
                   <Route
                      exact
                      path="/super_admin/contacts" 
                      element={<EmergencyContacts />}
                    />  
                   <Route
                      exact
                      path="/super_admin/profile"  
                      element={<ChangePassword />}
                    />  
                     <Route
                      exact
                      path="/super_admin/recipients"  
                      element={<Notifications />}
                    />  
                     <Route
                      exact
                      path="/super_admin/developers_tab"  
                      element={<DevelopersTab />}
                    />  
                    
                  </Route>                  
                  
                </Route>

                {/* Catch all */}
                <Route exact path="*" element={<Error400 />} />
              </Routes>
            </React.Suspense>
          </Provider>
        </AuthContextProvider>
      </BrowserRouter>
    
  ); 
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register()
// swDev()

