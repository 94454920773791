import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ADMIN, SUPER_ADMIN } from "./menu";
import { ArrowRight, ArrowLeft, Grid } from "react-feather";
import { Link } from "react-router-dom";
import configDB from "../../data/customizer/config";
import ContextData from "../../hooks/useAuth";
import { getCookie } from "src/services/cookies";

const Sidebar = (props) => {
  const { active_Emergency } = ContextData();
  const [ActiveEmergencyCount, setActiveEmergencyCount] = useState("");
  const [role, setRole] = useState("");

  //  get logged in user's data
  useEffect(()=>{
    const cookieData = getCookie("userDetails");
    const user_role = cookieData?.role ? cookieData?.role?.toString() : "";
    setRole(user_role); 
    
  },[])


  // set the ongoing emergency count
  useEffect(() => {
    setActiveEmergencyCount(active_Emergency);
  }, [active_Emergency]);

  const id = window.location.pathname;
  const layout = id ? id : "Dubai";
  const MENUITEMS = role === "admin" ? ADMIN : SUPER_ADMIN;
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const wrapper =
    useSelector((content) => content.Customizer.sidebar_types.type) ||
    configDB.data.settings.sidebar.type;

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "material-type" ||
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "advance-layout"
      )
        document.querySelector(".sidebar-main").className =
          "sidebar-main hovered";
    } else {
      document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };

  useEffect(() => {
    
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);
    handleResize();
   
    const get_user= layout?.split("/")
    const user_menu= get_user[1] === "admin" ? ADMIN : SUPER_ADMIN;
    const currentUrl= window.location.pathname.includes("/active_emergency_details") 
    ? `/${get_user[1]}/active_emergency`
    : window.location.pathname.includes("/resolved_emergency_details") 
    ? `/${get_user[1]}/resolved_emergency`
    : window.location.pathname
    
    // update the active menu state
    const updatedMenuData = user_menu.map((menuGroup) => {
      const updatedItems = menuGroup.Items.map((item) => {
        if (item.path === currentUrl) {
          item.active = true;
        } else {
          item.active = false;
          
        }

        if (item.children) {
          item.children.forEach((child) => {
            if (child.path !== currentUrl) {
              child.active = false;
            }

            if (child.path === currentUrl) {
              item.active= true
              child.active = true;
             
              
            }
          });

        }

        return item;
      });

      return {
        ...menuGroup,
        Items: updatedItems,
      };
    });
    setMainMenu(updatedMenuData)

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [layout]);


  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };
  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };
  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };
  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon";
  };

  const handleMenuClick = (menu, childMenu) => {
    
    const get_user= layout?.split("/")
    const currentUrl= window.location.pathname.includes("/active_emergency_details") 
    ? `/${get_user[1]}/active_emergency`
    : window.location.pathname.includes("/resolved_emergency_details") 
    ? `/${get_user[1]}/resolved_emergency`
    : window.location.pathname 
    

    const updatedMenuData = MENUITEMS.map((menuGroup) => {
      const updatedItems = menuGroup.Items.map((item) => {
        if(item.path !== currentUrl){
          if (item === menu) {
            item.active = true;
          } else {
            item.active = false;
          }
          if (item.children) {
            item.children.forEach((child) => {
              if (child !== childMenu) {
                child.active = false;
              }
            });
  
            if (item.children.includes(childMenu)) {
              item.active = true;
              childMenu.active = true;
            }
          }
  
        }
        return item;
      });

      return {
        ...menuGroup,
        Items: updatedItems,
      };
    });
    setMainMenu({ mainmenu: updatedMenuData });
  };

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link
            // to={
            //   role === "admin"
            //     ? `${process.env.PUBLIC_URL}/admin/devices`
            //     : `${process.env.PUBLIC_URL}/super_admin/devices`
            // }
            to={window.location.pathname}
          >
            <div style={{height:27}}>
            <img
              className="img-fluid for-light"
              src={require("../../assets/images/logo/logo2.png")}
              alt=""
              style={{ height: 45}} 
            />
             <img
              className="img-fluid for-dark"
              src={require("../../assets/images/logo/dark1.png")}
              alt=""
              style={{ height: 45 }}
            />

            </div>
            
           
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link  to=""
          // to={`${process.env.PUBLIC_URL}/devices`}
          >
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/icon.png")}
              alt=""
              width={27}
            />
          </Link>
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper.split(" ").includes("horizontal-wrapper")
                ? { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{"Back"}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              {MENUITEMS.map((Item, i) => (
                <Fragment key={i}>
                  <li className="sidebar-main-title">
                    <div>
                      <h6 className="lan-1">{Item.menutitle}</h6>
                      <p className="lan-2">{Item.menucontent}</p>
                    </div>
                  </li>
                  {/* Main Menu  */}
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {menuItem.type === "sub" ? (
                        <a
                          href="javascript"
                          className={`sidebar-link sidebar-title ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault(); 
                            handleMenuClick(menuItem,menuItem)
                          }}
                        >
                          {menuItem.icon ? <menuItem.icon /> : null } 
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.title === "Emergencies"
                                ? ActiveEmergencyCount
                                : menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}
                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={() => handleMenuClick(Item, menuItem)}
                        >
                          {menuItem.icon ? <menuItem.icon /> : null } 
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.title === "Emergencies"
                                ? ActiveEmergencyCount
                                : menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}
                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index}>
                                {childrenItem.type === "sub" ? (
                                  <a
                                    href="javascript"
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      handleMenuClick(menuItem, childrenItem)
                                    }}
                                  >
                                    {childrenItem.title}
                                    <span className="sub-arrow">
                                      <i className="fa fa-chevron-right"></i>
                                    </span>
                                    <div className="according-menu">
                                      {childrenItem.active ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-right"></i>
                                      )}
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}
                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={childrenItem.path}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    style={{ marginLeft: 4 }}
                                    onClick={() =>
                                      
                                      handleMenuClick(menuItem, childrenItem)
                                    }
                                  >
                                    {childrenItem.title}
                                    {childrenItem.badge ? (
                                      <label
                                        className={childrenItem.badge}
                                        style={{
                                          marginTop: -8,
                                          fontWeight: "bold",
                                          marginRight: -25,
                                        }}
                                      >
                                        {childrenItem.title === "Ongoing Emergencies"
                                          ? ActiveEmergencyCount
                                          : childrenItem.badgetxt}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {childrenItem.children ? (
                                  <ul
                                    className="nav-sub-childmenu submenu-content"
                                    style={
                                      childrenItem.active
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => (
                                        <li key={key}>
                                          {childrenSubItem.type === "link" ? (
                                            <Link
                                              to={childrenSubItem.path}
                                              className={`${
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                
                                                handleMenuClick(childrenItem, childrenSubItem)
                                              }
                                            >
                                              {childrenSubItem.title}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};
export default Sidebar;
