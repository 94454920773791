export const getCookie = (name) => { 
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    const cookieData = cookieValue ? cookieValue.pop() : "";
    const data_list = Object.keys(cookieData).length > 0 ? JSON.parse(decodeURIComponent(cookieData)) : {};
    return data_list;
  };


 export const setCookie =(name, value, daysToExpire)=> {
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);
  
    var cookieValue =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);
    cookieValue += "; expires=" + expirationDate.toUTCString();
    cookieValue += "; path=/"; 
  
    document.cookie = cookieValue;
  }

  export const getNotificationCookie = (name) => { 
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    const cookieData = cookieValue ? cookieValue.pop() : "";
    const data_list = Object.keys(cookieData).length > 0 ? JSON.parse(decodeURIComponent(cookieData)) : {};
    return data_list;
  };