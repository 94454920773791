import React, { Fragment } from 'react';
import {Row} from 'reactstrap'
import LeftHeader from './leftbar'
import RightHeader from './rightbar'

const Header = (props) => {

  return (
      <Fragment>
      <div className="page-header">
      <Row className="header-wrapper m-0 row" >
      <LeftHeader/>
      <RightHeader/>
      </Row>
   
      
      
     
    </div>
    {/* <div style={{backgroundColor:'yellowgreen'}}>
    <Row> <Breadcrumb /></Row>
    </div> */}
   
  
    </Fragment>
  );
}

export default Header;